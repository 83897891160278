<template>
    <div class="bg">
        
    </div>
</template>
<script>
    export default {
    };
</script>
<style lang="less" scoped>
.bg{
    width:100%;
    height: 280px;
    background:url('https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1573551030772&di=1366295aa7d916ae0259bff751a4c72b&imgtype=0&src=http%3A%2F%2Fimg.mp.sohu.com%2Fupload%2F20170727%2Fc85b96d1a9f14b75b7eabb464c1cac60_th.png') no-repeat left top/100% 100% 
}
</style>