<template>
  <div class="news">
    <!-- <bg></bg> -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="news_top">
      <el-breadcrumb-item :to="{ name:'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: prevUrl }" v-show="breadcrumb">{{prevtitle}}</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          name:`newsoption${activeNum}`,
          query:{
            idx:activeNum,
          }
       }"
      >{{leftNav[activeNum].text}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="news_space">
      <div class="auto-center">
        <div class="new_public">最新发布</div>
      </div>
    </div>
    <div class="news_content auto-center">
      <ul class="news_content_left">
        <!-- <router-link
          tag="li"
          :to="{
          name:`newsoption${item.id}`,
          query:{
            idx:item.id,
          }
          }"
          v-show="item.text"
          v-for="(item,index)  in leftNav"
          :class="{active:activeNum==item.id}"
          :key="index"
        >{{item.text}}</router-link>-->
        <router-link
          tag="li"
          :to="{name: 'nanfanNews'}"
          v-show="item.text"
          v-for="(item,index)  in leftNav"
          :class="{active:activeNum==item.id}"
          :key="index"
        >{{item.text}}</router-link>
      </ul>
      <div class="news_content_right">
        <transition name="el-zoom-in-center">
          <router-view :key="key"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import bg from "./bg";
export default {
  data() {
    return {
      activeNum: -1,
      columnIds: [
        "d1707e23348143fb99537c9bddffcdb3",
        "3373f13e83b74f12a213a53db20a6657",
        "abb9308a63f64190b55633b83cc27e12"
      ],
      leftNav: [
        {
          id: 0,
          text: "海南要闻"
        },
        {
          id: 1,
          text: "最新政策"
        },
        {
          id: 2,
          text: "通知公告"
        }
      ],
      prevUrl: "",
      prevtitle: "",
      breadcrumb: true
    };
  },
  provide() {},
  components: {
    bg
  },
  watch: {
    $route(to, from) {
      this.activeNum = this.$route.query.idx;
    }
  },
  computed: {
    key() {
      console.log(this.$route.query.idx);
      return this.$route.query.idx;
    }
  },
  created() {
    this.activeNum = this.$route.query.idx;
    this.prevUrl = this.$route.query.prev;
    switch (this.prevUrl) {
      case "searchNews":
        this.prevtitle = "新闻搜索";
        break;
      case "nanfanNews":
        this.prevtitle = "今日南繁";
        break;
      case "newsList":
        this.prevtitle = "新闻列表";
        break;
      default:
        this.breadcrumb = false;
        break;
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.news {
  width: 100%;
  .news_top {
    width: 1200px;
    margin: 15px auto;
  }
  .news_space {
    width: 100%;
    height: 100px;
    background-color: #f4f4f4;
    .new_public {
      width: 170px;
      height: 100%;
      line-height: 100px;
      text-align: center;
      font-size: 1.85rem;
      color: #f60;
    }
  }
  .news_content {
    display: flex;
    margin-top: -76px;
    .news_content_left {
      width: 170px;
      margin-top: 76px;
      li {
        background-color: #efefef;
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-family: SourceHanSansCN-Medium;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: -1px;
        color: #666666;
        cursor: pointer;
        &.active {
          background-color: #265ae1;
          color: #fff;
        }
      }
    }
    .news_content_right {
      flex: 1;
      padding: 0 10px;
      box-sizing: border-box;
      // background-color: #fff;
    }
  }
}
</style>