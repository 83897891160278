var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news"},[_c('el-breadcrumb',{staticClass:"news_top",attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ name:'home' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.breadcrumb),expression:"breadcrumb"}],attrs:{"to":{ name: _vm.prevUrl }}},[_vm._v(_vm._s(_vm.prevtitle))]),_c('el-breadcrumb-item',{attrs:{"to":{
        name:`newsoption${_vm.activeNum}`,
        query:{
          idx:_vm.activeNum,
        }
     }}},[_vm._v(_vm._s(_vm.leftNav[_vm.activeNum].text))])],1),_vm._m(0),_c('div',{staticClass:"news_content auto-center"},[_c('ul',{staticClass:"news_content_left"},_vm._l((_vm.leftNav),function(item,index){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(item.text),expression:"item.text"}],key:index,class:{active:_vm.activeNum==item.id},attrs:{"tag":"li","to":{name: 'nanfanNews'}}},[_vm._v(_vm._s(item.text))])}),1),_c('div',{staticClass:"news_content_right"},[_c('transition',{attrs:{"name":"el-zoom-in-center"}},[_c('router-view',{key:_vm.key})],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_space"},[_c('div',{staticClass:"auto-center"},[_c('div',{staticClass:"new_public"},[_vm._v("最新发布")])])])
}]

export { render, staticRenderFns }